export default function TowerIcon() {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7733 4.22703C17.4008 4.85344 17.8986 5.59743 18.2382 6.41643C18.5779 7.23543 18.7527 8.11337 18.7527 9C18.7527 9.88664 18.5779 10.7646 18.2382 11.5836C17.8986 12.4026 17.4008 13.1466 16.7733 13.773M7.22733 13.773C6.59985 13.1466 6.10204 12.4026 5.76239 11.5836C5.42274 10.7646 5.24791 9.88664 5.24791 9C5.24791 8.11337 5.42274 7.23543 5.76239 6.41643C6.10204 5.59743 6.59985 4.85344 7.22733 4.22703M19.4249 1.57538C20.401 2.54979 21.1754 3.70711 21.7037 4.98111C22.2321 6.25511 22.504 7.62079 22.504 9C22.504 10.3792 22.2321 11.7449 21.7037 13.0189C21.1754 14.2929 20.401 15.4502 19.4249 16.4246M4.57568 16.4246C3.59959 15.4502 2.82523 14.2929 2.29688 13.0189C1.76854 11.7449 1.49658 10.3792 1.49658 9.00001C1.49658 7.6208 1.76854 6.25512 2.29688 4.98112C2.82523 3.70712 3.59959 2.5498 4.57568 1.57539M15.0003 9C15.0003 10.6569 13.6572 12 12.0003 12C10.3434 12 9.0003 10.6569 9.0003 9C9.0003 7.34315 10.3434 6 12.0003 6C13.6572 6 15.0003 7.34315 15.0003 9Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
