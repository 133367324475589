import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import npGeoSlice from "./features/NP/geoInfo/geoSlice";
import bankSlice from "./features/admin/bank/bankSlice";
import branchSlice from "./features/admin/branch/branchSlice";
import adminDashboardSlice from "./features/admin/dashboard/dashboardSlice";
import networkSlice from "./features/admin/network/networkSlice";
import notificationSlice from "./features/admin/notification/notificationSlice";
import paymentSlice from "./features/admin/payment/paymentSlice";
import proprietorSlice from "./features/admin/proprietors/proprietorsSlice";
import appSlice from "./features/appSlice";
import loginSlice from "./features/auth/loginSlice";

export const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    login: loginSlice.reducer,
    npGeo: npGeoSlice.reducer,
    network: networkSlice.reducer,
    bank: bankSlice.reducer,
    branch: branchSlice.reducer,
    proprietor: proprietorSlice.reducer,
    payment: paymentSlice.reducer,
    notification: notificationSlice.reducer,
    adminDashboard: adminDashboardSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
