import { createSlice } from "@reduxjs/toolkit";
import { BankInitialStatus, GetBankData } from "../../../../types/redux";
import { createBankAsync, getAllBankAsync } from "./bankAPI";

const initialState: BankInitialStatus<GetBankData> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  viewLoading: false,
  allBanks: [],
  banksCount: 0,
};

const bankSlice = createSlice({
  name: "admin-bank",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create bank
    builder
      .addCase(createBankAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createBankAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createBankAsync.rejected, (state) => {
        state.postLoading = false;
      });
    // get all banks
    builder
      .addCase(getAllBankAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBankAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.allBanks = action.payload;
      })
      .addCase(getAllBankAsync.rejected, (state) => {
        state.loading = false;
        state.allBanks = [];
      });
  },
});

export default bankSlice;
