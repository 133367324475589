import { ReactNode } from "react";
import { Link } from "react-router-dom";
import ProfileIcon from "../../assets/icons/ProfileIcon";
import RightArrowIcon from "../../assets/icons/RightArrowIcon";
import { numberWithCommas } from "../../utils/number";

type Props = {
  duePayment: any;
  getPayableNumber: any[];
};

export default function TopBar({ duePayment, getPayableNumber }: Props) {
  // console.log(duePayment);
  // const dueData = duePayment?.paymentData
  //   ?.filter((el: any) => el?._id?.toLowerCase() === "due")
  //   ?.reduce((sum: number, el: any) => sum + el?.total || 0, 0);

  // const paidData = duePayment?.paymentData
  //   ?.filter((el: any) => el?._id?.toLowerCase() === "paid")
  //   ?.reduce((sum: number, el: any) => sum + el?.total || 0, 0);

  return (
    <section className="grid grid-cols-4 gap-6">
      <TopItem
        rootClassName="bg-[#f2f5ff] p-4 text-[#1B4DFF]"
        className="text-[#6e8eff]"
        icon={<ProfileIcon />}
        count={duePayment?.totalPropritor || 0}
      >
        Total Proprietors of Bangladesh
      </TopItem>
      {/* <TopItem
        rootClassName="bg-[#fff8df] p-4 text-[#b18a00]"
        className="text-[#d8a800]"
        icon={<ProfileIcon />}
        count={3547}
      >
        Total Proprietors of Dhaka Division
      </TopItem>
      <TopItem
        rootClassName="bg-[#f2fff8] p-4 text-[#10a65c]"
        className="text-[#2fd180]"
        icon={<ProfileIcon />}
        count={765}
      >
        Total Proprietors of Gazipur District
      </TopItem> */}
      {/* <TopItem
        rootClassName="bg-[#FFF5F4] p-4 text-[#FF3838]"
        className="text-[#ff7a71]"
        icon={<BankIcon />}
        count={paidData || 0}
      >
        Total Due Payment (BDT) of Rental Site{" "}
        <span className="text-[#FF3838] font-medium">
          ({numberWithCommas(dueData || 0)})
        </span>
      </TopItem> */}
      <Link to={`/admin/dashboard/payable-users`}>
        <TopItem
          rootClassName="bg-[#fff8df] p-4 text-[#b18a00] transition-shadow hover:shadow"
          className="text-[#d8a800]"
          icon={<ProfileIcon />}
          count={getPayableNumber?.reduce(
            (sum: number, el: any) => sum + el?.fieldN || 0,
            0
          )}
        >
          Total payable
        </TopItem>
      </Link>
    </section>
  );
}

type ItemProps = {
  rootClassName: string;
  className: string;
  icon: ReactNode;
  children: ReactNode;
  count: number;
};

const TopItem = ({
  rootClassName,
  className,
  children,
  icon,
  count,
}: ItemProps) => (
  <article className={`rounded-md ${rootClassName}`}>
    {icon}
    <p
      className={`!text-base font-normal leading-7 max-w-[200px] my-2 min-h-[50px] ${className}`}
    >
      {children}
    </p>

    <div className="text-2xl font-bold flex justify-between items-end">
      {numberWithCommas(count || 0)} <RightArrowIcon />
    </div>
  </article>
);
