import { Pagination, PaginationProps, Select, Table } from "antd";
import { AnyObject } from "antd/es/_util/type";
import { ColumnsType, TableProps } from "antd/es/table";
import { ReactNode } from "react";
import NextIcon from "../../assets/icons/NextIcon";
import PrevIcon from "../../assets/icons/PrevIcon";
import SelectDownIcon from "../../assets/icons/SelectDownIcon";
import { selectSearchOption } from "../../helpers/siteConfig";
import { AddZero } from "../../utils/number";

interface Props<DataListType> extends TableProps<any> {
  loading: boolean;
  total?: number;
  columns: ColumnsType<DataListType>;
  dataList: DataListType[];
  page: number;
  limit?: number;
  isSearch?: boolean;
  isPagination?: boolean;
  bordered?: boolean;
  handlePagination?: (paginationPage: number, paginationLimit: number) => void;
  tableTopChildren?: ReactNode;
  tableClass?: string;
  isLimitRow?: boolean;
  showTotalPage?: boolean;
  handleLimit?: (val: number) => void;
  isTopRow?: boolean;
  hideSpace?: boolean;
  hideTableColor?: boolean;
}

const CustomTable = <DataListType extends AnyObject>({
  loading,
  total = 1000,
  limit = 10,
  page,
  columns,
  dataList,
  isPagination = true,
  bordered = true,
  handlePagination,
  tableTopChildren,
  isTopRow = true,
  showTotalPage = false,
  hideTableColor = false,
  tableClass = "",
  isLimitRow,
  handleLimit,
  hideSpace,
  ...rest
}: Props<DataListType>) => {
  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return (
        <span>
          <PrevIcon />
        </span>
      );
    }
    if (type === "next") {
      return (
        <span>
          <NextIcon />
        </span>
      );
    }
    return originalElement;
  };

  const totalPage = Math.ceil(total / limit);

  return (
    <div className={!hideSpace ? "py-5" : ""}>
      {/* {tableTopChildren ? <div className="mb-4">{tableTopChildren}</div> : null} */}

      {isTopRow ? (
        <div
          className={`flex justify-between gap-5 items-center flex-wrap filter_section  ${
            isLimitRow || tableTopChildren ? "mb-4" : ""
          }`}
        >
          {isLimitRow ? (
            <div className="flex items-center gap-2 text-body text-sm font-semibold">
              <span className="font-open-sans">Total Rows</span>
              <Select
                size="middle"
                showSearch
                filterOption={selectSearchOption}
                value={limit}
                options={[
                  {
                    label: "10",
                    value: "10",
                  },
                  {
                    label: "20",
                    value: "20",
                  },
                  {
                    label: "50",
                    value: "50",
                  },
                  {
                    label: "100",
                    value: "100",
                  },
                  {
                    label: "1000",
                    value: "1000",
                  },
                ]}
                onChange={handleLimit}
                className="min-w-[70px] limit_dropdown"
                suffixIcon={<SelectDownIcon width={14} height={8} />}
              />
              <span className="font-open-sans">of {AddZero(total)}</span>
            </div>
          ) : null}

          {tableTopChildren ? (
            <div className="ml-auto ">{tableTopChildren}</div>
          ) : null}
        </div>
      ) : null}
      <Table
        columns={columns}
        dataSource={dataList}
        loading={loading}
        pagination={false}
        className={`${
          !hideTableColor ? "custom_edotco_table" : ""
        } color_table custom-sidebar ${tableClass}`}
        bordered={bordered}
        {...rest}
      />

      {isPagination && dataList?.length ? (
        <div className="flex justify-between mt-5 relative custom_pagination">
          <div className="text-[14] leading-6 font-open-sans text-body text-sm font-semibold">
            {showTotalPage ? (
              <>
                Showing {page} of {totalPage} {totalPage > 1 ? "Pages" : "Page"}
              </>
            ) : null}
          </div>

          <Pagination
            onChange={handlePagination}
            current={page}
            showSizeChanger={false}
            total={total}
            itemRender={itemRender}
            pageSize={limit}
            className="!font-open-sans"
          />
        </div>
      ) : null}
    </div>
  );
};

export default CustomTable;
