export default function ButtonEmailIcon() {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="relative top-[1.1px]"
    >
      <g id="Icon">
        <path
          id="Vector"
          d="M1.25 0.9375H14.75M1.25 0.9375V10.5C1.25 10.6492 1.30926 10.7923 1.41475 10.8977C1.52024 11.0032 1.66332 11.0625 1.8125 11.0625H14.1875C14.3367 11.0625 14.4798 11.0032 14.5852 10.8977C14.6907 10.7923 14.75 10.6492 14.75 10.5V0.9375M1.25 0.9375L8 7.125L14.75 0.9375"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
