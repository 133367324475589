export default function DocumentIcon() {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="ml-0.5"
    >
      <path
        d="M11.2498 1H2.24902C2.05011 1 1.85935 1.07902 1.71869 1.21967C1.57804 1.36032 1.49902 1.55109 1.49902 1.75V18.25C1.49902 18.4489 1.57804 18.6397 1.71869 18.7803C1.85935 18.921 2.05011 19 2.24902 19H15.7498C15.8482 19 15.9458 18.9806 16.0368 18.9429C16.1278 18.9052 16.2104 18.85 16.2801 18.7803C16.3497 18.7107 16.405 18.628 16.4427 18.537C16.4804 18.446 16.4998 18.3485 16.4998 18.25V6.25M11.2498 1L16.4998 6.25M11.2498 1V6.25H16.4998M5.99976 13.75H11.9998M5.99976 10.75H11.9998"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
