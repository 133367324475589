import { Space } from "antd";
import { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import BankIcon from "../../../assets/icons/BankIcon";
import DocumentIcon from "../../../assets/icons/DocumentIcon";
import LeftArrowIcon from "../../../assets/icons/LeftArrowIcon";
import TowerIcon from "../../../assets/icons/TowerIcon";
import UsersIcon from "../../../assets/icons/UsersIcon";
import AVATAR from "../../../assets/images/avater.png";
import IconButton from "../../../components/common/IconButton";
import InputTitle from "../../../components/common/InputTitle";
import Loader from "../../../components/common/Loader";
import DocumentItem from "../../../components/ui/DocumentItem";
import InfoItem from "../../../components/ui/InfoItem";
import Subheader from "../../../components/ui/Subheader";
import { IMAGE_URL } from "../../../helpers/config";
import AdminLayout from "../../../layouts/AdminLayout";
import { getSingleProprietorsAsync } from "../../../store/features/admin/proprietors/proprietorsAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { stringToArray } from "../../../utils/array";
import { formatDate } from "../../../utils/time";

export default function ProprietorsManageInformation() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { singleProprietor, viewLoading } = useAppSelector(
    (state) => state.proprietor
  );
  // console.log(singleProprietor);
  const pathArr = stringToArray(location?.pathname, "/");
  const navLink = pathArr?.slice(0, pathArr?.length - 1)?.join("/");

  useEffect(() => {
    if (id) dispatch(getSingleProprietorsAsync({ params: { id } }));
  }, [dispatch, id]);

  return (
    <AdminLayout title={`Information of ${singleProprietor?.fullName}`}>
      {viewLoading ? (
        <Loader />
      ) : (
        <section className="roboto_font">
          <Subheader title={`Information of ${singleProprietor?.fullName}`}>
            <Space size="middle">
              <Link to={"/" + navLink}>
                <IconButton
                  className="!border-others-border !text-sm !font-normal !rounded-lg !border !text-body !bg-transparent font-roboto"
                  type="default"
                  icon={<LeftArrowIcon />}
                >
                  <span>Back</span>
                </IconButton>
              </Link>
            </Space>
          </Subheader>

          <div className="max-w-[800px] mx-auto p-6 mt-6">
            <InputTitle
              icon={<UsersIcon />}
              title="Proprietor’s Information"
              hideBorder
            />

            <div className="grid grid-cols-115 gap-2.5">
              <div>
                <img
                  className="max-w-[142px] h-full w-full object-cover"
                  src={
                    singleProprietor?.photo?.[0]
                      ? `${IMAGE_URL + singleProprietor?.photo?.[0]}`
                      : AVATAR
                  }
                  alt=""
                />
              </div>
              <div className="info_items bg-[#f9fafb]">
                <InfoItem title="Proprietor’s Name:">
                  {singleProprietor?.fullName}
                </InfoItem>
                <InfoItem title="Date of Birth:">
                  {formatDate(singleProprietor?.dob)}
                </InfoItem>
                <InfoItem title="Mobile Number:">
                  {singleProprietor?.activeMobileNumber}
                </InfoItem>
                <InfoItem title="Email Address:">
                  {singleProprietor?.email}
                </InfoItem>
              </div>
            </div>
            <div className="info_items mt-2.5 mb-1.5">
              <InfoItem title="Father’s Name:">
                {singleProprietor?.fatherName}
              </InfoItem>
              <InfoItem title="Mother’s Name:">
                {singleProprietor?.montherName}
              </InfoItem>
              <InfoItem title="NID No:">{singleProprietor?.nid}</InfoItem>
              <InfoItem title="Passport No:">
                {singleProprietor?.passportNumber}
              </InfoItem>
              <InfoItem title="Address:">{singleProprietor?.address}</InfoItem>
            </div>

            <InputTitle
              icon={<TowerIcon />}
              title="Tower Information"
              hideBorder
            />

            <div className="info_items mb-2">
              <InfoItem title="Tower/Site name:">
                {singleProprietor?.towerId?.towerName}
              </InfoItem>
              <InfoItem title="Site Code:">
                {singleProprietor?.towerId?.siteCode}
              </InfoItem>
              <InfoItem title="Region:">
                {singleProprietor?.towerId?.regionName}
              </InfoItem>
              <InfoItem title="Area:">
                {singleProprietor?.towerId?.areaName}
              </InfoItem>
              {/* <InfoItem title="Territory:">
              {singleProprietor?.towerId?.teritoryName}
            </InfoItem> */}
            </div>

            <InputTitle
              icon={<BankIcon />}
              title="Payment & Bank Information"
              hideBorder
              className="!mt-0"
            />

            <div className="info_items mb-1.5">
              <InfoItem title="Advance Amount:">
                {singleProprietor?.paymentBankInfo?.advanceAmount} TK
              </InfoItem>
              <InfoItem title="Monthly Rent:">
                {singleProprietor?.paymentBankInfo?.monthlyRentAmount} TK
              </InfoItem>
              <InfoItem title="Bank Name:">
                {singleProprietor?.paymentBankInfo?.bankName}
              </InfoItem>
              <InfoItem title="Account Title:">
                {singleProprietor?.paymentBankInfo?.accountTitle}
              </InfoItem>
              <InfoItem title="Routing No:">
                {singleProprietor?.paymentBankInfo?.routingNumber}
              </InfoItem>
              <InfoItem title="Branch:">
                {singleProprietor?.paymentBankInfo?.branchName}
              </InfoItem>
            </div>

            <InputTitle
              icon={<DocumentIcon />}
              title="Document Attachment"
              hideBorder
            />

            <div className="info_items mb-1.5">
              <DocumentItem
                title="Agreement Paper"
                date={singleProprietor?.document?.aggrementPaperExpireDate}
                file={singleProprietor?.document?.aggrementPaper}
                id={singleProprietor?._id || ""}
                type="ag"
              />
              <DocumentItem
                title="Tax Certificate"
                date={singleProprietor?.document?.taxCertificateExpiryDate}
                file={singleProprietor?.document?.TaxCertificate}
                id={singleProprietor?._id || ""}
                type="tax"
              />
              <DocumentItem
                title="TIN Certificate"
                file={singleProprietor?.document?.TinCertificate}
                id={singleProprietor?._id || ""}
                type="tin"
              />
              <DocumentItem
                title="NID"
                file={singleProprietor?.document?.nid}
                id={singleProprietor?._id || ""}
                type="nid"
              />
              <DocumentItem
                title="Passport"
                file={singleProprietor?.document?.passport}
                id={singleProprietor?._id || ""}
                type="pp"
              />
            </div>
          </div>

          {/* <div className="w-full justify-center flex gap-2 pt-3">
          <Link className="delete_btn" to={navLink}>
            <Button style={{ width: 130 }} size="large">
              Delete
            </Button>
          </Link>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            style={{ width: 120 }}
          >
            Update
          </Button>
        </div> */}
        </section>
      )}
    </AdminLayout>
  );
}
