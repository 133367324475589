import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import {
  CommonOthers,
  // CommonOthers,
  ErrorMessage,
  GetPaymentData,
  GetPaymentParams,
  GetSingleDataParams,
  Params,
  PaymentData,
  // GetNetworkLogData,
  // GetSingleDataParams,
  // Params,
  PostRequest,
} from "../../../../types/redux";

// create a payments
export const createPaymentAsync = createAsyncThunk<
  null,
  PostRequest<PaymentData, CommonOthers>
>(
  "admin-payments/createPayments",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createPayment, data);
      reRender(true);
      toast("success", "Payment create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Payment create fail");
      return rejectWithValue(error);
    }
  }
);

// get all payments
export const getAllPaymentsAsync = createAsyncThunk<
  GetPaymentData[],
  Params<GetPaymentParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-payments/getAllPayments", async ({ params }, { rejectWithValue }) => {
  try {
    const getData = await AXIOS.get(BASE_API.getAllPayment, {
      params,
    });

    return getData.data?.getData as GetPaymentData[];
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get all payments data fail");
    return rejectWithValue(error);
  }
});

// // get single Payments
export const getSinglePaymentsAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-payments/getSinglePayment",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(
        `${BASE_API.getSinglePayment}/${params?.id}`
      );

      return data.data?.getData as any;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get payment fail");
      return rejectWithValue(error);
    }
  }
);

export const uploadPaymentAsync = createAsyncThunk<
  null,
  PostRequest<any[], any>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-payments/uploadPayment",
  async (
    { data, others: { reRender, uploadedCount } },
    { rejectWithValue }
  ) => {
    const totalItems = data.length;
    let completedItems = 0;
    // let hasError = false;
    let isCreate = false;

    try {
      const uploadPromises = data.map(async (el: any) => {
        try {
          // First API call to create the payment
          const paymentResponse = await AXIOS.post(BASE_API.createPayment, el, {
            onUploadProgress: () => {
              completedItems++;
              const progressPercentage = Math.round(
                (completedItems / totalItems) * 100
              );
              uploadedCount(progressPercentage, 100);
            },
          });

          const ticketId = paymentResponse?.data;
          isCreate = true;

          if (!ticketId) {
            console.error("Payment ID not found in response:", paymentResponse);
            throw new Error("Payment ID not found in response");
          }

          // Second API call to send the notification
          // await AXIOS.post(BASE_API.towerTicketNotification, {
          //   towerTicketId: ticketId,
          // });
        } catch (uploadError) {
          // hasError = true;
          // console.error("Error during upload process:", uploadError);
          const errors = uploadError as AxiosError;
          const error = errors.response?.data as ErrorMessage;
          toast("error", error?.message || "Payment created failed");
        } finally {
          // Always increment completedItems and update progress bar
          completedItems++;
          const progressPercentage = Math.round(
            (completedItems / totalItems) * 100
          );
          uploadedCount(progressPercentage, 100);
        }
      });

      // Wait for all promises to settle (either resolved or rejected)
      await Promise.allSettled(uploadPromises);

      // Show error toast if any upload failed
      // if (hasError) {
      //   toast(
      //     "error",
      //     "Some tickets failed to create. Please check the log for more details."
      //   );
      // } else {
      //   toast("success", "All tickets created successfully");
      // }

      if (isCreate) {
        toast("success", "Payment created successfully");
      }

      // Call reRender function after all uploads are complete
      reRender(true);

      return null;
    } catch (err) {
      // console.error("Final Error:", err);
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Payment created failed");
      return rejectWithValue(error);
    }
  }
);
