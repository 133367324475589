import { Button, Upload, UploadFile, UploadProps } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import { useEffect, useState } from "react";
import FileIcon from "../../assets/icons/FileIcon";
import AXIOS from "../../helpers/api";
import { BASE_API } from "../../helpers/apiUrl";
import { toast } from "../../helpers/toast";
import { stringToArray } from "../../utils/array";
import { sliceString } from "../../utils/string";

type Props = {
  errorMessage?: string;
  form: any;
  name: any;
  disabled?: boolean;
  type?: boolean;
  accept?: string;
  onChange?: (url: string | null) => void;
} & UploadProps;

export default function FileUpload({
  errorMessage = "File upload fail",
  form,
  name,
  disabled,
  type,
  onChange,
  accept = ".png,.jpeg,.svg",
  ...rest
}: Props) {
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("filename");
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleUpload = async (file: any) => {
    const formData = new FormData();
    formData.append("photo", file);
    formData.append("name", file.name);

    setLoading(true);

    try {
      const response = await AXIOS.post(`${BASE_API.fileUpload}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const url = response?.data?.photo;
      const fileNameArr = stringToArray(url, ".");

      if (response.status === 200) {
        setFileName(
          `${sliceString(url, 10, "...")}.${
            fileNameArr?.[fileNameArr?.length - 1]
          }`
        );

        onChange && onChange(url);

        // form.setFieldsValue({ [name]: url });
        setLoading(false);
      }
    } catch (error) {
      toast("error", errorMessage);
      setLoading(false);
    }

    return false;
  };

  const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
    setFileList(info.fileList);

    if (info.file.status === "removed") {
      onChange && onChange(null);
      // form.setFieldsValue({ [name]: null });
      setFileName("");
    }
  };

  useEffect(() => {
    if (type) {
      const file = form.getFieldValue([name]);
      const fileNameArr = stringToArray(file, ".");

      setFileName(
        `${sliceString(file, 15, "...")}.${
          fileNameArr?.[fileNameArr?.length - 1]
        }`
      );
    }
  }, [form, name, type]);

  return (
    <Upload
      name="photo"
      beforeUpload={handleUpload}
      maxCount={1}
      accept={accept}
      className="custom-upload"
      showUploadList={false}
      onChange={handleChange}
      fileList={fileList}
      disabled={disabled}
      style={{ width: "100%" }}
      {...rest}
    >
      <Button
        className="flex justify-between items-center"
        size="large"
        disabled={disabled}
        style={{ width: "100%" }}
      >
        <div className="flex items-center gap-2 text-sm font-normal font-roboto text-[#8797ae]">
          <span className="text-accent font-medium text-base leading-7 relative">
            Choose File
          </span>
          <span className="border-r border-solid border-l-0 border-y-0 border-others-border h-4" />
          {loading ? "Uploading..." : fileName}
        </div>
        <FileIcon />
      </Button>
    </Upload>
  );
}
