import { ReactNode } from "react";

type Props = {
  icon: ReactNode;
  title: string;
  className?: string;
  hideBorder?: boolean;
};

export default function InputTitle({
  icon,
  title,
  hideBorder,
  className = "",
}: Props) {
  return (
    <h5
      className={`flex items-center gap-2 text-primary mb-2 ${
        !hideBorder
          ? "pb-2.5 border-b border-solid border-t-0 border-x-0 border-others-border"
          : ""
      } `}
    >
      {icon}
      <span
        className={`text-lg font-medium font-roboto leading-[30px] mt-1 ${className}`}
      >
        {title}
      </span>
    </h5>
  );
}
