import { Select, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddIcon from "../../../../assets/icons/AddIcon";
import SelectDownIcon from "../../../../assets/icons/SelectDownIcon";
import CustomTable from "../../../../components/common/CustomTable";
import IconButton from "../../../../components/common/IconButton";
import Subheader from "../../../../components/ui/Subheader";
import {
  addKeyInArray,
  ArrayOption,
  selectSearchOption,
} from "../../../../helpers/siteConfig";
import useTable from "../../../../hooks/useTable";
import AdminLayout from "../../../../layouts/AdminLayout";
import { getAllBankAsync } from "../../../../store/features/admin/bank/bankAPI";
import { getAllBranchAsync } from "../../../../store/features/admin/branch/branchAPI";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import {
  GetBranchData,
  GetBranchParams,
  TableData,
} from "../../../../types/redux";
import { countRow } from "../../../../utils/number";

export default function Branch() {
  const dispatch = useAppDispatch();
  const {
    limit,
    page,
    handlePagination,
    handleLimit,
    isFirstPage,
    handleSetFirstPage,
  } = useTable();
  const { allBanks } = useAppSelector((state) => state.bank);
  const { allBranch, loading } = useAppSelector((state) => state.branch);
  const [bank, setBank] = useState("");
  const allBranchData = allBranch?.slice((page - 1) * limit, limit * page);

  const columns: ColumnsType<TableData<GetBranchData>> = [
    {
      title: "#",
      align: "center",
      width: 60,
      render: (_, __, idx) => countRow(page, limit, idx),
    },
    {
      title: "Branch Name",
      dataIndex: "name",
    },
    {
      title: "Branch",
      dataIndex: "bank",
      render: (val) => val?.name,
    },
    // {
    //   title: "Action",
    //   align: "center",
    //   width: 120,
    //   render: (val: GetBranchData) => (
    //     <Space size="small">
    //       <Link to={`/admin/bank-manage/bank/view/${val?._id}`}>
    //         <Button className="action-button" icon={<ViewIcon />} />
    //       </Link>
    //       <Link to={`/admin/bank-manage/bank/update/${val?._id}`}>
    //         <Button className="action-button" icon={<EditIcon />} />
    //       </Link>
    //     </Space>
    //   ),
    // },
  ];

  useEffect(() => {
    // const params: CommonParams = {
    //   limit: limit,
    //   skip: limit * (page - 1),
    // };
    const params: GetBranchParams = {
      bank,
    };

    dispatch(
      getAllBranchAsync({
        params,
      })
    );
  }, [bank, dispatch]);

  useEffect(() => {
    // dispatch(getCountTerritoryAsync());
    dispatch(getAllBankAsync(null));
  }, [dispatch]);

  useEffect(() => {
    //  || agreement || keyword || tax
    if (bank && isFirstPage) {
      handlePagination(1, 10);
      handleSetFirstPage(false);
    }
  }, [bank, handlePagination, handleSetFirstPage, isFirstPage]);

  return (
    <AdminLayout title="Branch List">
      <Subheader title="Branch List">
        <Space size="middle">
          <Link to="/admin/bank-manage/branch/create">
            <IconButton type="text" className="primary-btn" icon={<AddIcon />}>
              Add New
            </IconButton>
          </Link>
        </Space>
      </Subheader>

      <CustomTable<TableData<GetBranchData>>
        loading={loading}
        total={allBranch?.length}
        limit={limit}
        page={page}
        handlePagination={handlePagination}
        columns={columns}
        dataList={addKeyInArray(allBranchData)}
        bordered={false}
        isLimitRow
        showTotalPage={true}
        handleLimit={handleLimit}
        // isPagination={false}
        tableTopChildren={
          <Space className="flex-wrap justify-end" size={"small"}>
            <Select
              showSearch
              filterOption={selectSearchOption}
              placeholder="Bank"
              options={ArrayOption(allBanks, "name", "_id")}
              onChange={(val) => {
                setBank(val);
                handleSetFirstPage(true);
              }}
              allowClear
              className="min-w-[7rem]"
              suffixIcon={<SelectDownIcon width={14} height={8} />}
            />
          </Space>
        }
      />
    </AdminLayout>
  );
}
