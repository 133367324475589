import moment, { Moment } from "moment";

export const formatDate = (
  date?: string | Date,
  format: string = "DD/MM/YYYY"
) => {
  return moment(date || new Date()).format(format);
};

export const formatTime = (
  date?: string | Date,
  format: string = "h:mm:ss A"
) => {
  return moment(date || new Date()).format(format);
};

export const formatUTCTime = (date: string | Moment) => {
  // const dateData = new Date(date);

  // const UTCTime = new Date(
  //   dateData.getTime() - dateData.getTimezoneOffset() * 60000
  // );

  // return UTCTime.toISOString();
  // console.log(moment.utc(date).local().toISOString());

  return moment.utc(date).local().toISOString();
};

// calculate just minutes
export const calculateMinutes = (startTime: string, endTime: string) => {
  const start = moment(startTime, "h:mm A");
  const end = moment(endTime, "h:mm A");

  return end.diff(start, "minutes");
};

// calculate minute with date
export const calculateMinutesWithDate = (
  startTime: string,
  endTime: string
) => {
  const start = moment(startTime, "YYYY-MM-DDTH:mm A");
  const end = moment(endTime, "YYYY-MM-DDTH:mm A");

  return end.diff(start, "minutes");
};

export const minutesToHours = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const minute = minutes % 60;
  return `${String(hours).padStart(2, "0")}:${String(minute).padStart(2, "0")}`;
};

export const isExpired = (expirationDate: string, format = "DD/MM/YYYY") => {
  const currentDate = moment();
  const date = moment(expirationDate).format(format);
  return moment(date, format).isBefore(currentDate);
};

export const disableTodayAndFutureDates = (current: any) => {
  return current && current >= moment().startOf("day");
};
