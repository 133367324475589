import { Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import AddIcon from "../../../../assets/icons/AddIcon";
import CustomTable from "../../../../components/common/CustomTable";
import IconButton from "../../../../components/common/IconButton";
import Subheader from "../../../../components/ui/Subheader";
import { addKeyInArray } from "../../../../helpers/siteConfig";
import useTable from "../../../../hooks/useTable";
import AdminLayout from "../../../../layouts/AdminLayout";
import { getAllBankAsync } from "../../../../store/features/admin/bank/bankAPI";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { GetBankData, TableData } from "../../../../types/redux";
import { countRow } from "../../../../utils/number";

export default function Bank() {
  const dispatch = useAppDispatch();
  const { limit, page, handlePagination, handleLimit } = useTable();
  const { allBanks, loading } = useAppSelector((state) => state.bank);
  const allBanksData = allBanks?.slice((page - 1) * limit, limit * page);

  const columns: ColumnsType<TableData<GetBankData>> = [
    {
      title: "#",
      align: "center",
      width: 60,
      render: (_, __, idx) => countRow(page, limit, idx),
    },
    {
      title: "Bank Name",
      dataIndex: "name",
    },
    // {
    //   title: "Action",
    //   align: "center",
    //   width: 120,
    //   render: (val: GetTerritoryData) => (
    //     <Space size="small">
    //       <Link to={`/admin/bank-manage/bank/view/${val?._id}`}>
    //         <Button className="action-button" icon={<ViewIcon />} />
    //       </Link>
    //       <Link to={`/admin/bank-manage/bank/update/${val?._id}`}>
    //         <Button className="action-button" icon={<EditIcon />} />
    //       </Link>
    //     </Space>
    //   ),
    // },
  ];

  useEffect(() => {
    // const params: CommonParams = {
    //   limit: limit,
    //   skip: limit * (page - 1),
    // };

    // dispatch(
    //   getAllBankAsync({
    //     params,
    //   })
    // );
    dispatch(getAllBankAsync(null));
  }, [dispatch, limit, page]);

  // useEffect(() => {
  //   dispatch(getCountTerritoryAsync());
  // }, [dispatch]);

  return (
    <AdminLayout title="Bank List">
      <Subheader title="Bank List">
        <Space size="middle">
          <Link to="/admin/bank-manage/bank/create">
            <IconButton type="text" className="primary-btn" icon={<AddIcon />}>
              Add New
            </IconButton>
          </Link>
        </Space>
      </Subheader>

      <CustomTable<TableData<GetBankData>>
        loading={loading}
        total={allBanks?.length}
        limit={limit}
        page={page}
        handlePagination={handlePagination}
        columns={columns}
        dataList={addKeyInArray(allBanksData)}
        bordered={false}
        isLimitRow
        showTotalPage={true}
        handleLimit={handleLimit}
        // isPagination={false}
        // tableTopChildren={
        //   <Space className="flex-wrap justify-end" size={"small"}>
        //     <Input
        //       suffix={<SearchIcon />}
        //       placeholder="Search by Name, Code, Tower"
        //       className="min-w-[18rem]"
        //     />
        //   </Space>
        // }
      />
    </AdminLayout>
  );
}
