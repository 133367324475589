import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import {
  BranchData,
  CommonOthers,
  // CommonOthers,
  ErrorMessage,
  GetBranchData,
  GetBranchParams,
  Params,
  // GetNetworkLogData,
  // GetSingleDataParams,
  // Params,
  PostRequest,
} from "../../../../types/redux";

// create a branch
export const createBranchAsync = createAsyncThunk<
  null,
  PostRequest<BranchData, CommonOthers>
>(
  "admin-branch/createBranch",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createBranch, data);
      reRender(true);
      toast("success", "Branch create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Branch create fail");
      return rejectWithValue(error);
    }
  }
);

// get all branch
export const getAllBranchAsync = createAsyncThunk<
  GetBranchData[],
  Params<GetBranchParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-branch/getAllBranch", async ({ params }, { rejectWithValue }) => {
  try {
    const getData = await AXIOS.get(BASE_API.getAllBranch, {
      params,
    });

    return getData?.data?.getData as GetBranchData[];
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get all branch data fail");
    return rejectWithValue(error);
  }
});
