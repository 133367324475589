export default function DateIcon() {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="relative -top-[1px]"
    >
      <path
        d="M10.5 12.1247L12 10.9997V15.8747M5.625 10.9997H8.25L6.75 12.8747C6.99664 12.8747 7.23947 12.9355 7.45699 13.0518C7.67452 13.168 7.86002 13.3361 7.99708 13.5411C8.13413 13.7462 8.21851 13.9819 8.24274 14.2273C8.26697 14.4728 8.23031 14.7204 8.13599 14.9483C8.04168 15.1762 7.89263 15.3773 7.70203 15.5338C7.51144 15.6904 7.28518 15.7975 7.04331 15.8457C6.80143 15.894 6.55139 15.8818 6.31534 15.8103C6.07928 15.7389 5.86449 15.6103 5.68999 15.436M0.75 7.25H17.25M13.5 1.25V4.25M4.5 1.25V4.25M1.5 2.75H16.5C16.9142 2.75 17.25 3.08579 17.25 3.5V18.5C17.25 18.9142 16.9142 19.25 16.5 19.25H1.5C1.08579 19.25 0.75 18.9142 0.75 18.5V3.5C0.75 3.08579 1.08579 2.75 1.5 2.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
