import { createSlice } from "@reduxjs/toolkit";

import { GetPaymentData, PaymentsInitialStatus } from "../../../../types/redux";
import {
  createPaymentAsync,
  getAllPaymentsAsync,
  getSinglePaymentsAsync,
} from "./paymentAPI";

const initialState: PaymentsInitialStatus<GetPaymentData, any> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  viewLoading: false,
  allPayments: [],
  singlePayment: null,
  paymentCount: 0,
};

const paymentSlice = createSlice({
  name: "admin-payments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create payment
    builder
      .addCase(createPaymentAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createPaymentAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createPaymentAsync.rejected, (state) => {
        state.postLoading = false;
      });
    // get all payments
    builder
      .addCase(getAllPaymentsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPaymentsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.allPayments = action.payload;
      })
      .addCase(getAllPaymentsAsync.rejected, (state) => {
        state.loading = false;
        state.allPayments = [];
      });
    // get single payments
    builder
      .addCase(getSinglePaymentsAsync.pending, (state) => {
        state.viewLoading = true;
      })
      .addCase(getSinglePaymentsAsync.fulfilled, (state, action) => {
        state.viewLoading = false;
        state.singlePayment = action.payload;
      })
      .addCase(getSinglePaymentsAsync.rejected, (state) => {
        state.viewLoading = false;
        state.singlePayment = null;
      });
  },
});

export default paymentSlice;
