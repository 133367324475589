export default function SettingIcon() {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="ml-0.5"
    >
      <path
        d="M18.625 15.4372V6.56241C18.625 6.4296 18.5897 6.29917 18.5228 6.18445C18.4559 6.06973 18.3597 5.97485 18.244 5.90949L10.369 1.45841C10.2565 1.39477 10.1293 1.36133 10 1.36133C9.87067 1.36133 9.74354 1.39477 9.63096 1.45841L1.75596 5.90949C1.64033 5.97485 1.54414 6.06973 1.4772 6.18445C1.41027 6.29917 1.375 6.4296 1.375 6.56241V15.4372C1.375 15.57 1.41027 15.7005 1.4772 15.8152C1.54414 15.9299 1.64033 16.0248 1.75596 16.0901L9.63096 20.5412C9.74354 20.6049 9.87067 20.6383 10 20.6383C10.1293 20.6383 10.2565 20.6049 10.369 20.5412L18.244 16.0901C18.3597 16.0248 18.4559 15.9299 18.5228 15.8152C18.5897 15.7005 18.625 15.57 18.625 15.4372Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 14.3748C11.864 14.3748 13.375 12.8638 13.375 10.9998C13.375 9.13585 11.864 7.62482 10 7.62482C8.13604 7.62482 6.625 9.13585 6.625 10.9998C6.625 12.8638 8.13604 14.3748 10 14.3748Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
