export default function PaymentIcon() {
  return (
    <svg
      width="24"
      height="14"
      viewBox="0 0 24 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 13L22.5 7.75M7.5 13L1.5 7.75M16.5 1L22.5 6.25M7.5 1L1.5 6.25M2.25 1H21.75C22.1642 1 22.5 1.33579 22.5 1.75V12.25C22.5 12.6642 22.1642 13 21.75 13H2.25C1.83579 13 1.5 12.6642 1.5 12.25V1.75C1.5 1.33579 1.83579 1 2.25 1ZM15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
