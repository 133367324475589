import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import {
  CommonOthers,
  // CommonOthers,
  ErrorMessage,
  GetNotificationData,
  NotificationData,
  NotificationParams,
  Params,
  // GetNetworkLogData,
  // GetSingleDataParams,
  // Params,
  PostRequest,
} from "../../../../types/redux";

// create a notification
export const createNotificationAsync = createAsyncThunk<
  null,
  PostRequest<NotificationData, CommonOthers>
>(
  "admin-notification/createNotification",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createNotification, data);
      reRender(true);
      toast("success", "Notification send successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Notification send fail");
      return rejectWithValue(error);
    }
  }
);

// get all notifications
export const getAllNotificationAsync = createAsyncThunk<
  GetNotificationData[],
  Params<NotificationParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-notification/getAllNotification", async (_, { rejectWithValue }) => {
  try {
    const getData = await AXIOS.get(BASE_API.getAllNotification);

    return getData?.data?.getData as GetNotificationData[];
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get all notification data fail");
    return rejectWithValue(error);
  }
});
