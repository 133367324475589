import { Button, Input, Select, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DownloadIcon from "../../../assets/icons/DownloadIcon";
import SearchIcon from "../../../assets/icons/SearchIcon";
import SelectDownIcon from "../../../assets/icons/SelectDownIcon";
import CustomTable from "../../../components/common/CustomTable";
import IconButton from "../../../components/common/IconButton";
import Subheader from "../../../components/ui/Subheader";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import {
  addKeyInArray,
  ArrayOption,
  selectSearchOption,
} from "../../../helpers/siteConfig";
import { toast } from "../../../helpers/toast";
import useDebounce from "../../../hooks/useDebounce";
import useExcelDownload from "../../../hooks/useExcelDownload";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import { getAllPaymentsAsync } from "../../../store/features/admin/payment/paymentAPI";
import {
  getAllAreaAsync,
  getAllRegionAsync,
  getCountTerritoryAsync,
} from "../../../store/features/NP/geoInfo/geoAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { GetPaymentData, TableData } from "../../../types/redux";
import { countRow } from "../../../utils/number";
import { capitalize } from "../../../utils/string";

export default function Payment() {
  const dispatch = useAppDispatch();
  // const { open, toggle, handleOpen } = useModal();
  const [selectedData, setSelectedData] = useState<GetPaymentData[]>([]);
  const {
    limit,
    page,
    handlePagination,
    handleLimit,
    handleSetFirstPage,
    isFirstPage,
  } = useTable(1, 10);
  const [status, setStatus] = useState(undefined);
  const [region, setRegion] = useState<undefined | string>(undefined);
  const [area, setArea] = useState<undefined | string>(undefined);
  const { regions, areas } = useAppSelector((state) => state.npGeo);
  const { keyword, handleInputChange } = useDebounce();
  const { loading, allPayments } = useAppSelector((state) => state.payment);
  const { excelLoading, downloadExcel, handleExcelLoading } =
    useExcelDownload("Payment-List");
  // const [uploadCount, setUploadCount] = useState(0);
  // const [totalCount, setTotalCount] = useState(0);
  const allPaymentsData = allPayments?.slice((page - 1) * limit, limit * page);

  const columns: ColumnsType<TableData<GetPaymentData>> = [
    {
      title: "#",
      align: "center",
      width: 60,
      render: (_, __, idx) => countRow(page, limit, idx),
    },
    {
      title: "Site name & Code",
      dataIndex: "tower",
      render: (val) => (
        <div>
          <span className="font-medium font-roboto">
            {capitalize(val?.towerName || "")}
          </span>
          <br />
          <small className="text-xs font-normal leading-tight tracking-tight text-accent">
            Site Code: {val?.siteCode}
          </small>
        </div>
      ),
    },
    {
      title: "Proprietor’s name",
      dataIndex: "proprietor",
      render: (val) => val?.fullName,
    },
    {
      title: "Contact No.",
      dataIndex: "proprietor",
      render: (val) => val?.activeMobileNumber,
    },
    {
      title: "A/C Title",
      // dataIndex: "paymentBankInfo",
      render: (val) => val?.accountTitle,
    },
    {
      title: "A/C No.",
      // dataIndex: "paymentBankInfo",
      render: (val) => val?.accountNumber,
    },
    {
      title: "Routing No.",
      // dataIndex: "paymentBankInfo",
      render: (val) => val?.routingNumber,
    },
    {
      title: "Branch",
      // dataIndex: "paymentBankInfo",
      dataIndex: "proprietor",
      render: (val) => val?.paymentBankInfo?.branchName,
    },
    {
      title: "Status",
      align: "center",
      render: (val) => (
        <span
          className={`status
              ${
                val?.status?.toLowerCase() === "paid"
                  ? "status-done"
                  : "status-pending"
              }`}
        >
          {capitalize(val?.status)}
        </span>
      ),
    },
    {
      title: "Amount",
      // dataIndex: "paymentBankInfo",
      render: (val) => `${val?.monthlyRentAmount || 0} tk`,
    },
    {
      title: "Action",
      align: "center",
      render: (val) => (
        <Link to={`/admin/payment/details/${val?._id}`}>
          <Button type="primary">Open</Button>
        </Link>
      ),
    },
  ];

  const handleDownloadExcel = async () => {
    handleExcelLoading(true);
    let excelData = [] as any[];

    if (selectedData?.length) {
      excelDataList(selectedData, excelData);
    } else {
      await AXIOS.get(BASE_API.getAllPayment)
        .then((res) => {
          const responseData = res?.data?.getData as GetPaymentData[];
          excelDataList(responseData, excelData);
        })
        .catch(() => {
          handleExcelLoading(false);
          toast("error", "Download fail! Please try again");
        });
    }

    downloadExcel(excelData);
  };

  function excelDataList(data: GetPaymentData[], excelData: any[]) {
    data?.forEach((el: GetPaymentData) => {
      let data = {} as any;
      data["Tower Name"] = capitalize(el?.towerName);
      data["Site Code"] = "";
      data["Proprietor’s name"] = el?.fullName;
      data["Contact No."] = el?.activeMobileNumber;
      data["A/C Title"] = el?.paymentBankInfo?.accountTitle;
      data["A/C No."] = el?.paymentBankInfo?.accountNumber;
      data["Routing No."] = el?.paymentBankInfo?.routingNumber;
      data["Branch"] = el?.paymentBankInfo?.branch;
      data["Status"] = el?.status || "Due";
      data["Amount"] = el?.paymentBankInfo?.monthlyRentAmount;

      excelData.push(data);
    });
  }

  const getData = useCallback(() => {
    const params = {
      limit: limit,
      skip: limit * (page - 1),
      name: keyword || "",
      status,
      region,
      area,
    };

    dispatch(
      getAllPaymentsAsync({
        params,
      })
    );
  }, [area, dispatch, keyword, limit, page, region, status]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    dispatch(getCountTerritoryAsync());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllRegionAsync({}));
  }, [dispatch]);
  useEffect(() => {
    if (region) {
      dispatch(getAllAreaAsync({ params: { region } }));
    }
  }, [dispatch, region]);

  useEffect(() => {
    if ((region || area || status || keyword) && isFirstPage) {
      handlePagination(1, 10);
      handleSetFirstPage(false);
    }
  }, [
    area,
    handlePagination,
    handleSetFirstPage,
    isFirstPage,
    keyword,
    region,
    status,
  ]);

  // const uploadedCount = (value: number, total: number) => {
  //   setUploadCount(value);
  //   setTotalCount(total);
  // };

  // function reRender(val: boolean) {
  //   if (val) {
  //     getData();
  //     toggle();
  //   }
  // }

  return (
    <AdminLayout title="Payment List">
      {/* {postLoading && uploadCount && totalCount ? (
        <Progress
          className="fixed w-full left-0 right-0 -top-[7px] z-[99999999999999999999999999999999]"
          percent={percentage(uploadCount / 2, totalCount)}
          showInfo={false}
          success={{ strokeColor: "#384DDA" }}
        />
      ) : null} */}

      {/* {open?.type === "IMPORT" && (
        <ImportExcel
          open={open.open}
          handleClose={toggle}
          reRender={reRender}
          uploadedCount={uploadedCount}
        />
      )} */}
      <Subheader title="Payment List">
        <Space size="middle">
          <IconButton
            nextIcon={<DownloadIcon />}
            size="large"
            loading={excelLoading}
            onClick={handleDownloadExcel}
          >
            Export Excel
          </IconButton>
          {/* <IconButton
            nextIcon={<DownloadIcon />}
            type="primary"
            size="large"
            onClick={() => {
              handleOpen({
                type: "IMPORT",
              });
            }}
          >
            Import Excel
          </IconButton> */}
        </Space>
      </Subheader>

      <CustomTable<TableData<GetPaymentData>>
        loading={loading}
        // total={territoryCount}
        total={allPayments?.length}
        limit={limit}
        page={page}
        handlePagination={handlePagination}
        columns={columns}
        dataList={addKeyInArray(allPaymentsData)}
        // bordered={false}
        isLimitRow={true}
        showTotalPage={true}
        handleLimit={handleLimit}
        // tableClass="bill_generate_table"
        rowSelection={{
          type: "checkbox",
          onChange: (_, data) => setSelectedData(data),
          columnWidth: 45,
        }}
        tableTopChildren={
          <Space className="flex-wrap justify-end" size={"small"}>
            <Input
              suffix={<SearchIcon />}
              // , Code, Tower
              placeholder="Search by Name"
              className="min-w-[18rem]"
              onChange={(val) => {
                handleInputChange(val);
                handleSetFirstPage(true);
              }}
            />
            <Select
              allowClear
              showSearch
              value={region}
              placeholder="Region"
              filterOption={selectSearchOption}
              options={ArrayOption(regions, "regionName", "_id")}
              onChange={(val) => {
                setRegion(val);
                setArea(undefined);
                handleSetFirstPage(true);
              }}
              className="min-w-[7rem]"
              suffixIcon={<SelectDownIcon width={14} height={8} />}
            />
            <Select
              allowClear
              showSearch
              value={area}
              placeholder={"Area"}
              filterOption={selectSearchOption}
              options={ArrayOption(areas, "areaName", "_id")}
              onChange={(val) => {
                setArea(val);
                handleSetFirstPage(true);
              }}
              disabled={!region}
              className="min-w-[7rem]"
              suffixIcon={<SelectDownIcon width={14} height={8} />}
            />
            <Select
              allowClear
              showSearch
              filterOption={selectSearchOption}
              placeholder={"Status"}
              options={[
                {
                  label: "Paid",
                  value: "Paid",
                },
                {
                  label: "Due",
                  value: "Due",
                },
              ]}
              onChange={(val) => {
                setStatus(val);
                handleSetFirstPage(true);
              }}
              className="min-w-[5.4rem]"
              suffixIcon={<SelectDownIcon width={14} height={8} />}
            />
          </Space>
        }
      />
    </AdminLayout>
  );
}
