import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import { ErrorMessage, Params } from "../../../../types/redux";

// get all Region
export const getDuePaymentAsync = createAsyncThunk<
  any[],
  null,
  {
    rejectValue: ErrorMessage;
  }
>("admin-dashboard/getDuePayment", async (_, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getDuePayment);

    return data.data?.getData as any[];
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get due payment fail");
    return rejectWithValue(error);
  }
});

export const getSiteDataAsync = createAsyncThunk<
  any[],
  null,
  {
    rejectValue: ErrorMessage;
  }
>("admin-dashboard/getSiteData", async (_, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getSiteData);

    return data.data?.getData as any[];
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get site data fail");
    return rejectWithValue(error);
  }
});

// get payable number
export const getPayableNumberAsync = createAsyncThunk<
  any[],
  Params<any>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-dashboard/getPayableNumber",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(BASE_API.getPayableNumber, { params });

      return data.data?.payable as any[];
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get payable number fail");
      return rejectWithValue(error);
    }
  }
);

// get payable number
export const getPayableUserAsync = createAsyncThunk<
  any[],
  Params<any>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-dashboard/getPayableUser", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getPayableUser, { params });

    return data.data?.getData as any[];
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get payable user fail");
    return rejectWithValue(error);
  }
});
