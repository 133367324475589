import { createSlice } from "@reduxjs/toolkit";
import {
  GetNotificationData,
  NotificationInitialStatus,
} from "../../../../types/redux";
import {
  createNotificationAsync,
  getAllNotificationAsync,
} from "./notificationAPI";

const initialState: NotificationInitialStatus<GetNotificationData> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  viewLoading: false,
  headerNotifications: [],
  allNotifications: [],
  notificationsCount: 0,
};

const notificationSlice = createSlice({
  name: "admin-notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create notification
    builder
      .addCase(createNotificationAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createNotificationAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createNotificationAsync.rejected, (state) => {
        state.postLoading = false;
      });
    // get all notifications
    builder
      .addCase(getAllNotificationAsync.pending, (state, action) => {
        if (action?.meta?.arg?.params?.isHeader) {
          state.viewLoading = true;
        } else {
          state.loading = true;
        }
      })
      .addCase(getAllNotificationAsync.fulfilled, (state, action) => {
        if (action?.meta?.arg?.params?.isHeader) {
          state.viewLoading = false;
          state.headerNotifications = action.payload;
        } else {
          state.loading = false;
          state.allNotifications = action.payload;
        }
      })
      .addCase(getAllNotificationAsync.rejected, (state) => {
        state.loading = false;
        state.viewLoading = false;
        state.allNotifications = [];
        state.headerNotifications = [];
      });
  },
});

export default notificationSlice;
