import { createSlice } from "@reduxjs/toolkit";
import { DashboardInitialStatus } from "../../../../types/redux";
import {
  getDuePaymentAsync,
  getPayableNumberAsync,
  getPayableUserAsync,
  getSiteDataAsync,
} from "./dashboardAPI";

const initialState: DashboardInitialStatus<any, any, any, any> = {
  loading: false,
  duePayment: [],
  getSiteData: [],
  getPayableNumber: [],
  getPayableUser: [],
};

const adminDashboardSlice = createSlice({
  name: "admin-dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDuePaymentAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDuePaymentAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.duePayment = action.payload;
      })
      .addCase(getDuePaymentAsync.rejected, (state) => {
        state.loading = false;
        state.duePayment = [];
      });
    builder
      .addCase(getSiteDataAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSiteDataAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.getSiteData = action.payload;
      })
      .addCase(getSiteDataAsync.rejected, (state) => {
        state.loading = false;
        state.getSiteData = [];
      });
    builder
      .addCase(getPayableNumberAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPayableNumberAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.getPayableNumber = action.payload || [];
      })
      .addCase(getPayableNumberAsync.rejected, (state) => {
        state.loading = false;
        state.getPayableNumber = [];
      });
    builder
      .addCase(getPayableUserAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPayableUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.getPayableUser = action.payload || [];
      })
      .addCase(getPayableUserAsync.rejected, (state) => {
        state.loading = false;
        state.getPayableUser = [];
      });
  },
});

export default adminDashboardSlice;
