import { Button, ButtonProps, Form, FormProps, Space } from "antd";
import { HTMLAttributes, ReactNode } from "react";
import { Link } from "react-router-dom";
import LeftArrowIcon from "../../assets/icons/LeftArrowIcon";
import Subheader from "../ui/Subheader";
import IconButton from "./IconButton";

interface Props<T> extends HTMLAttributes<HTMLDivElement> {
  title: string;
  formTitle?: string | ReactNode;
  backLink: string;
  submitBtnName?: string;
  disabled?: boolean;
  disabledSubmit?: boolean;
  onFinish: (props: T) => void;
  className?: string;
  children: ReactNode;
  formProps?: FormProps;
  buttonProps?: ButtonProps;
  isUpdate?: boolean;
  hideBtn?: boolean;
  maxWidth?: number | string;
}

export default function CustomForm<T>({
  title,
  formTitle,
  backLink,
  isUpdate = false,
  submitBtnName = isUpdate ? "Update" : "Save",
  onFinish,
  className = "",
  children,
  disabled = false,
  disabledSubmit = false,
  hideBtn = true,
  formProps,
  buttonProps,
  maxWidth = 860,
  ...rest
}: Props<T>) {
  const btnProps = {
    style: { width: 130, ...buttonProps?.style },
    ...buttonProps,
  };

  return (
    <section
      {...rest}
      className={`shadow-sm bg-white rounded-lg pb-7 ${className}`}
    >
      <Subheader title={title}>
        <Space size="middle">
          <Link to={backLink}>
            <IconButton
              className="!border-others-border !text-sm !font-normal !rounded-lg !border !text-body !bg-transparent font-roboto"
              type="default"
              icon={<LeftArrowIcon />}
            >
              <span>Back</span>
            </IconButton>
          </Link>
        </Space>
      </Subheader>

      <div className="mt-6">
        <div
          className="mx-auto p-6 bg-white rounded-lg border-solid shadow-sm border border-others-cancel"
          style={{ maxWidth }}
        >
          <h5 className="text-lg font-semibold text-header pb-2.5 mb-2 border-b border-solid border-t-0 border-x-0 border-others-border">
            {formTitle}
          </h5>
          <Form
            className="custom-form hide_star_mark"
            layout="vertical"
            onFinish={onFinish}
            size="large"
            {...formProps}
          >
            {children}
            {!hideBtn ? (
              <div className="w-full justify-center flex gap-2 pt-3">
                <Link className="delete_btn" to={disabled ? "" : backLink}>
                  <Button
                    disabled={disabled}
                    style={{ width: 130 }}
                    size="large"
                  >
                    Cancel
                  </Button>
                </Link>
                <Button
                  type="primary"
                  disabled={disabledSubmit || disabled}
                  size="large"
                  htmlType="submit"
                  loading={disabled}
                  {...btnProps}
                >
                  {submitBtnName}
                </Button>
              </div>
            ) : null}
          </Form>
        </div>
      </div>
    </section>
  );
}
