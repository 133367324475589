import { IMAGE_URL } from "../../helpers/config";
import { CustomModalProps } from "../../types";
import CustomModal from "../common/CustomModal";
import Image from "../common/Image";

type Props = {
  data?: any;
} & CustomModalProps;

export default function ImageModal({ handleClose, data, ...rest }: Props) {
  return (
    <CustomModal width={750} handleClose={handleClose} {...rest}>
      <div className="flex justify-center">
        <Image
          width={"100%"}
          src={IMAGE_URL + data}
          style={{
            objectFit: "contain",
          }}
        />
      </div>
    </CustomModal>
  );
}
