import { createSlice } from "@reduxjs/toolkit";
import { BranchInitialStatus, GetBranchData } from "../../../../types/redux";
import { createBranchAsync, getAllBranchAsync } from "./branchAPI";

const initialState: BranchInitialStatus<GetBranchData> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  viewLoading: false,
  allBranch: [],
  branchCount: 0,
};

const branchSlice = createSlice({
  name: "admin-branch",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create branch
    builder
      .addCase(createBranchAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createBranchAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createBranchAsync.rejected, (state) => {
        state.postLoading = false;
      });
    // get all branch
    builder
      .addCase(getAllBranchAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBranchAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.allBranch = action.payload;
      })
      .addCase(getAllBranchAsync.rejected, (state) => {
        state.loading = false;
        state.allBranch = [];
      });
  },
});

export default branchSlice;
