import { Card, Empty, Pagination, PaginationProps, Select, Space } from "antd";
import moment from "moment";
import { useCallback, useEffect } from "react";
import NextIcon from "../../../assets/icons/NextIcon";
import PrevIcon from "../../../assets/icons/PrevIcon";
import ReloadIcon from "../../../assets/icons/ReloadIcon";
import SelectDownIcon from "../../../assets/icons/SelectDownIcon";
import EmptyImage from "../../../assets/images/empty.svg";
import IconButton from "../../../components/common/IconButton";
import Loader from "../../../components/common/Loader";
import Subheader from "../../../components/ui/Subheader";
import { IMG, selectSearchOption } from "../../../helpers/siteConfig";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import { getAllNotificationAsync } from "../../../store/features/admin/notification/notificationAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { GetNotificationData } from "../../../types/redux";
import { notificationDateFilter } from "../../../utils/array";
import { AddZero } from "../../../utils/number";
// import { formatDate } from "../../../utils/time";

// const { RangePicker } = DatePicker;

// const dateFormat = "DD-MM-YYYY";

// interface DateRange {
//   startDate: Dayjs | null;
//   endDate: Dayjs | null;
// }

export default function Notifications() {
  const dispatch = useAppDispatch();
  const { limit, page, handlePagination, handleLimit } = useTable(1, 10);
  const { allNotifications, loading } = useAppSelector(
    (state) => state.notification
  );

  const allNotificationsData = allNotifications?.slice(
    (page - 1) * limit,
    limit * page
  );
  // const [date, setDate] = useState<DateRange>({
  //   startDate: dayjs(),
  //   endDate: dayjs(),
  // });

  // const newDate = {
  //   startDate: formatDate(date?.startDate?.format(), dateFormat),
  //   endDate: formatDate(date?.endDate?.format(), dateFormat),
  // };

  // console.log(newDate);

  const total = allNotifications?.length;
  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return (
        <span>
          <PrevIcon />
        </span>
      );
    }
    if (type === "next") {
      return (
        <span>
          <NextIcon />
        </span>
      );
    }
    return originalElement;
  };

  const totalPage = Math.ceil(total / limit);

  type NotificationData = {
    showDate: string;
  } & GetNotificationData;

  const notificationData = notificationDateFilter(
    allNotificationsData
  ) as NotificationData[];

  const getData = useCallback(() => {
    dispatch(getAllNotificationAsync({}));
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleReload = () => {
    // setDate({
    //   startDate: "",
    //   endDate: "",
    // });
    handlePagination(1, 10);
    getData();
  };

  // console.log(dayjs(date?.startDate), dayjs(date?.endDate));

  return (
    <AdminLayout title="Notifications">
      <Subheader title="Notifications">
        <Space className="top_bar_input" size="middle">
          <IconButton
            className="reload_btn"
            icon={<ReloadIcon />}
            size="large"
            onClick={handleReload}
          >
            Reload
          </IconButton>
        </Space>
      </Subheader>

      <div
        className={`flex justify-between gap-5 items-center flex-wrap filter_section mb-1`}
      >
        <div className="flex items-center gap-2 text-body text-sm font-semibold">
          <span className="font-open-sans">Total Rows</span>
          <Select
            size="middle"
            showSearch
            filterOption={selectSearchOption}
            value={limit}
            options={[
              {
                label: "10",
                value: "10",
              },
              {
                label: "20",
                value: "20",
              },
              {
                label: "50",
                value: "50",
              },
              {
                label: "100",
                value: "100",
              },
              {
                label: "1000",
                value: "1000",
              },
            ]}
            onChange={handleLimit}
            className="w-[70px] limit_dropdown"
            suffixIcon={<SelectDownIcon width={14} height={8} />}
          />
          <span className="font-open-sans">of {AddZero(total)}</span>
        </div>

        {/* <div className="ml-auto ">
          <RangePicker
            size="large"
            format={dateFormat}
            className="w-[250px]"
            value={[date.startDate, date.endDate]}
            onChange={(val) =>
              setDate({
                startDate: val?.[0] ? val[0] : null,
                endDate: val?.[1] ? val[1] : null,
              })
            }
          />
        </div> */}
      </div>

      {loading ? (
        <Loader />
      ) : notificationData?.length ? (
        <>
          <section className="overflow-x-auto">
            <div className="min-w-[400px]">
              {notificationData?.map((el, idx) => (
                <article key={idx}>
                  {el?.showDate ? (
                    <h5 className="text-body text-base font-medium leading-7 py-1.5 border-t-0 border-x-0 border-b border-solid border-others-gray ">
                      {moment(el?.showDate).format("dddd")}
                    </h5>
                  ) : (
                    <div className="border border-solid border-others-gray first:!border-t-0 border-t-[1px]">
                      <div className="grid grid-cols-80 gap-1.5 py-1.5 pl-1.5">
                        <div className="flex items-center justify-center">
                          <img
                            className="w-10 h-10 object-cover rounded-full"
                            src={IMG}
                            alt=""
                          />
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="relative -top-0.5">
                            <h6 className="text-body text-base font-medium leading-7">
                              {el?.proprietor?.fullName || "Proprietor"}
                              <span className="text-accent text-sm leading-normal ml-1.5">
                                {moment(el?.createdAt)
                                  .startOf("hour")
                                  .fromNow()}
                              </span>
                            </h6>

                            <div className="text-[#7d88f4] text-sm font-normal leading-normal">
                              {el?.description}
                            </div>
                          </div>

                          {/* <Button
                            className="normal_btn mr-4"
                            icon={<ViewIcon />}
                          >
                            <span className="relative -top-[1px]">View</span>
                          </Button> */}
                        </div>
                      </div>
                    </div>
                  )}
                </article>
              ))}
            </div>
          </section>

          <div className="flex justify-between mt-5 relative custom_pagination">
            <div className="text-[14] leading-6 font-open-sans text-body text-sm font-semibold">
              Showing {page} of {totalPage} {totalPage > 1 ? "Pages" : "Page"}
            </div>

            <Pagination
              onChange={handlePagination}
              current={page}
              showSizeChanger={false}
              total={total}
              itemRender={itemRender}
              pageSize={limit}
              className="!font-open-sans"
            />
          </div>
        </>
      ) : (
        <Card className="mt-4">
          <Empty
            image={EmptyImage}
            imageStyle={{ marginBottom: 0, height: 70 }}
            rootClassName="py-20"
            description={
              <span className="text-[#8797ae] block -mt-2 text-sm font-normal font-roboto leading-7">
                Empty
              </span>
            }
          />
        </Card>
      )}
    </AdminLayout>
  );
}
