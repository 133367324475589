export default function SearchIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4431 14.4438L18.9995 19.0001M16.75 8.875C16.75 13.2242 13.2242 16.75 8.875 16.75C4.52576 16.75 1 13.2242 1 8.875C1 4.52576 4.52576 1 8.875 1C13.2242 1 16.75 4.52576 16.75 8.875Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
