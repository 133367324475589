import moment from "moment";
import { useCallback, useEffect } from "react";
import DivisionWiseRentalCost from "../../components/admin/DivisionWiseRentalCost";
import TopBar from "../../components/admin/TopBar";
import Loader from "../../components/common/Loader";
import AdminLayout from "../../layouts/AdminLayout";
import {
  getDuePaymentAsync,
  getPayableNumberAsync,
  getSiteDataAsync,
} from "../../store/features/admin/dashboard/dashboardAPI";
import { useAppDispatch, useAppSelector } from "../../store/store";

export default function Dashboard() {
  const dispatch = useAppDispatch();
  const { loading, duePayment, getSiteData, getPayableNumber } = useAppSelector(
    (state) => state.adminDashboard
  );

  const getData = useCallback(() => {
    dispatch(getDuePaymentAsync(null));
    dispatch(getSiteDataAsync(null));
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    dispatch(
      getPayableNumberAsync({
        params: {
          month: moment().format("MMMM").toLowerCase(),
          year: moment().year(),
        },
      })
    );
  }, [dispatch]);

  return (
    <AdminLayout
      title="Dashboard"
      headerTitle="Dashboard"
      className="dashboard"
    >
      {loading ? (
        <div className="h-28">
          <Loader />
        </div>
      ) : null}

      <section className="mt-6">
        <TopBar duePayment={duePayment} getPayableNumber={getPayableNumber} />
        <div className="grid grid-cols-3 gap-6 mt-6">
          <DivisionWiseRentalCost data={getSiteData?.arr} />
          {/* <DocumentExpired /> */}
        </div>
      </section>
    </AdminLayout>
  );
}
