import img from "../assets/images/placeholder-image.png";
import { capitalize } from "../utils/string";

export const selectSearchOption = (
  input: string,
  option?: { label: string; value: string }
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

export const addKeyInArray = (data: any) => {
  return data?.map((el: object & { _id?: string }, idx: number) => ({
    ...el,
    key: el?._id || `${idx}`,
  }));
};

export const ArrayOption = (
  data: any[],
  label: string,
  value: string,
  ctz?: boolean
) => {
  return data.map((el) => ({
    label: ctz ? capitalize(el[label]) : el[label],
    value: el[value],
  }));
};

// placeholder images
export const IMG = img;
