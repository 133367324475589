import { Avatar, Dropdown } from "antd";
import { Link } from "react-router-dom";
import BottomDropdownIcon from "../../assets/icons/BottomDropdownIcon";
import { IMG } from "../../helpers/siteConfig";
import { userLogout } from "../../store/features/auth/loginSlice";
import { useAppDispatch, useAppSelector } from "../../store/store";

export default function ProfileMenu() {
  const dispatch = useAppDispatch();
  const { login } = useAppSelector((state) => state.login);

  return (
    <Dropdown
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
      className="cursor-pointer"
      rootClassName="profile-menu !left-auto !right-5"
      dropdownRender={() => (
        <ul className="list-none bg-white shadow p-1 rounded">
          <li>
            <Link
              to="/admin/settings"
              className="py-1.5 px-8 cursor-pointer select-none text-body hover:text-primary hover:bg-status-info-bg text-sm rounded block"
            >
              Profile
            </Link>
          </li>
          <li
            onClick={() => dispatch(userLogout())}
            className="py-1.5 px-8 cursor-pointer select-none text-body hover:text-primary hover:bg-status-info-bg text-sm rounded"
          >
            Logout
          </li>
        </ul>
      )}
    >
      <div className="flex items-center gap-2.5 cursor-pointer">
        <Avatar size={44} src={IMG} />
        <span className="text-[#555b6d] text-base font-medium flex items-center">
          {login?.firstName} <BottomDropdownIcon />
        </span>
        {/* {login?.profilePicture ? (
          <img
            className="w-9 h-9 rounded-full"
            src={login?.profilePicture}
            alt={"User"}
          />
        ) : (
          <button className="w-9 h-9 rounded-full bg-accent-normal border-none text-white text-lg font-semibold cursor-pointer">
            {firstChar(login?.name)}
          </button>
        )} */}
      </div>
    </Dropdown>
  );
}
