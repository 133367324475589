import { createSlice } from "@reduxjs/toolkit";
import {
  GeoInitialStatus,
  GetAreaData,
  GetRegionData,
  GetTerritoryData,
} from "../../../../types/redux";
import {
  createAreaAsync,
  createRegionAsync,
  createTerritoryAsync,
  getAllAreaAsync,
  getAllRegionAsync,
  getAllTerritoryAsync,
  getCountAreaAsync,
  getCountRegionAsync,
  getCountTerritoryAsync,
  getSingleAreaAsync,
  getSingleRegionAsync,
  getSingleTerritoryAsync,
  updateAreaAsync,
  updateRegionAsync,
  updateTerritoryAsync,
} from "./geoAPI";

const initialState: GeoInitialStatus<
  GetRegionData,
  GetAreaData,
  GetTerritoryData
> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  regions: [],
  othersRegions: [],
  areas: [],
  othersAreas: [],
  territorys: [],
  othersTerritorys: [],
  singleRegion: null,
  singleArea: null,
  singleTerritory: null,
  regionCount: 0,
  areaCount: 0,
  territoryCount: 0,
};

const geoSlice = createSlice({
  name: "np-geo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // region
    builder
      .addCase(createRegionAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createRegionAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createRegionAsync.rejected, (state) => {
        state.postLoading = false;
      });
    builder
      .addCase(updateRegionAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateRegionAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateRegionAsync.rejected, (state) => {
        state.updateLoading = false;
      });
    builder
      .addCase(getAllRegionAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllRegionAsync.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.meta?.arg?.params?.isOther) {
          state.othersRegions = action.payload;
        } else {
          state.regions = action.payload;
        }
      })
      .addCase(getAllRegionAsync.rejected, (state) => {
        state.loading = false;
        state.regions = [];
      });
    builder
      .addCase(getSingleRegionAsync.pending, (state) => {
        state.viewLoading = true;
      })
      .addCase(getSingleRegionAsync.fulfilled, (state, action) => {
        state.viewLoading = false;
        state.singleRegion = action.payload;
      })
      .addCase(getSingleRegionAsync.rejected, (state) => {
        state.viewLoading = false;
        state.singleRegion = null;
      });
    builder
      .addCase(getCountRegionAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCountRegionAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.regionCount = action.payload;
      })
      .addCase(getCountRegionAsync.rejected, (state) => {
        state.loading = false;
        state.regionCount = 0;
      });
    // area
    builder
      .addCase(createAreaAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createAreaAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createAreaAsync.rejected, (state) => {
        state.postLoading = false;
      });
    builder
      .addCase(updateAreaAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateAreaAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateAreaAsync.rejected, (state) => {
        state.updateLoading = false;
      });
    builder
      .addCase(getAllAreaAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAreaAsync.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.meta?.arg?.params?.isOther) {
          state.othersAreas = action.payload;
        } else {
          state.areas = action.payload;
        }
      })
      .addCase(getAllAreaAsync.rejected, (state) => {
        state.loading = false;
        state.areas = [];
      });
    builder
      .addCase(getSingleAreaAsync.pending, (state) => {
        state.viewLoading = true;
      })
      .addCase(getSingleAreaAsync.fulfilled, (state, action) => {
        state.viewLoading = false;
        state.singleArea = action.payload;
      })
      .addCase(getSingleAreaAsync.rejected, (state) => {
        state.viewLoading = false;
        state.singleArea = null;
      });
    builder
      .addCase(getCountAreaAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCountAreaAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.areaCount = action.payload;
      })
      .addCase(getCountAreaAsync.rejected, (state) => {
        state.loading = false;
        state.areaCount = 0;
      });
    // territory
    builder
      .addCase(createTerritoryAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createTerritoryAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createTerritoryAsync.rejected, (state) => {
        state.postLoading = false;
      });
    builder
      .addCase(updateTerritoryAsync.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateTerritoryAsync.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateTerritoryAsync.rejected, (state) => {
        state.updateLoading = false;
      });
    builder
      .addCase(getAllTerritoryAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTerritoryAsync.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.meta?.arg?.params?.isOther) {
          state.othersTerritorys = action.payload;
        } else {
          state.territorys = action.payload;
        }
      })
      .addCase(getAllTerritoryAsync.rejected, (state) => {
        state.loading = false;
        state.territorys = [];
      });
    builder
      .addCase(getSingleTerritoryAsync.pending, (state) => {
        state.viewLoading = true;
      })
      .addCase(getSingleTerritoryAsync.fulfilled, (state, action) => {
        state.viewLoading = false;
        state.singleTerritory = action.payload;
      })
      .addCase(getSingleTerritoryAsync.rejected, (state) => {
        state.viewLoading = false;
        state.singleTerritory = null;
      });
    builder
      .addCase(getCountTerritoryAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCountTerritoryAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.territoryCount = action.payload;
      })
      .addCase(getCountTerritoryAsync.rejected, (state) => {
        state.loading = false;
        state.territoryCount = 0;
      });
  },
});

export default geoSlice;
