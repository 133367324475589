import {
  Badge,
  Button,
  Drawer,
  Dropdown,
  Empty,
  Layout,
  Menu,
  MenuProps,
  theme,
} from "antd";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import withSidebar from "../HOC/withSidebar";
import DocumentIcon from "../assets/icons/DocumentIcon";
import NotificationHeaderIcon from "../assets/icons/NotificationHeaderIcon";
import NotificationIcon from "../assets/icons/NotificationIcon";
import DashboardIcon from "../assets/icons/sidebar/DashboardIcon";
import PaymentIcon from "../assets/icons/sidebar/PaymentIcon";
import ProprietorsManageIcon from "../assets/icons/sidebar/ProprietorsManageIcon";
import SettingIcon from "../assets/icons/sidebar/SettingIcon";
import SidebarBashIcon from "../assets/icons/sidebar/SidebarBashIcon";
import EmptyImage from "../assets/images/empty.svg";
import MainLogo from "../assets/images/main-logo.svg";
import Image from "../components/common/Image";
import Loader from "../components/common/Loader";
import ProfileMenu from "../components/common/ProfileMenu";
import { APP_NAME } from "../helpers/config";
import { IMG } from "../helpers/siteConfig";
import { getAllNotificationAsync } from "../store/features/admin/notification/notificationAPI";
import { useAppDispatch, useAppSelector } from "../store/store";
import { activePathString, pathString, stringToArray } from "../utils/array";

const { Header, Sider, Content } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

export type SidebarProps = {
  collapsed: boolean;
  openSidebar: boolean;
  openMenuKeys: string[];
  handleOpenMenu: () => void;
  handleCollapsed: () => void;
  handleShowSidebar: () => void;
  handleCloseSidebar: () => void;
};

const SidebarLayout: React.FC<SidebarProps> = ({
  collapsed,
  openSidebar,
  openMenuKeys,
  handleOpenMenu,
  handleCollapsed,
  handleShowSidebar,
  handleCloseSidebar,
}) => {
  let dynamicParams = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { siteRole } = useAppSelector((state) => state.login);
  const [openDropdown, setOpenDropdown] = useState(false);
  const { headerNotifications, viewLoading } = useAppSelector(
    (state) => state.notification
  );
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const isSA = stringToArray(location.pathname, "/")?.[0] === "sa";

  const dynamicPath = Object.keys(dynamicParams || {})?.length;

  // console.log(dynamicPath);

  function getItem(
    label: React.ReactNode,
    path: string,
    icon?: React.ReactNode,
    children?: MenuItem[],
    roles?: string[]
  ): MenuItem {
    return {
      key: pathString(path),
      icon,
      children,
      label: children?.length ? label : <Link to={path}>{label}</Link>,
      roles,
    } as MenuItem;
  }

  const AdminMenuItems: MenuItem[] = [
    getItem("Dashboard", "/admin/dashboard", <DashboardIcon />),
    getItem(
      "Proprietor’s Manage",
      "/admin/proprietors-manage",
      <ProprietorsManageIcon />
    ),
    getItem("Payment", "/admin/payment", <PaymentIcon />),
    getItem("Notification", "/admin/notifications", <NotificationIcon />),
    getItem("Bank Manage", "/admin/bank-manage", <DocumentIcon />, [
      getItem("Bank", "/admin/bank-manage/bank"),
      getItem("Branch", "/admin/bank-manage/branch"),
      // getItem("geo-details", "/sa/geo-info/geo-details"),
    ]),
    getItem("Settings", "/admin/settings", <SettingIcon />),
  ];

  const FinanceMenuItems: MenuItem[] = [
    // getItem("Dashboard", "/finance/dashboard", <DashboardIcon />),
    // getItem("Pay Bill", "/finance/pay-bill", <BillGenerateIcon />),
    // getItem("Settings", "/finance/settings", <SettingIcon />),
  ];

  function getActiveMenu(data: any[]) {
    const strArr = stringToArray(location.pathname, "/");
    return data?.find((el) => el?.key === strArr[1])?.children ? true : false;
  }

  useEffect(() => {
    dispatch(getAllNotificationAsync({ params: { isHeader: true } }));
  }, [dispatch]);

  const getHeaderNotifications = headerNotifications?.slice(0, 5);

  return (
    <Layout className="min-h-screen">
      <div className="md:hidden">
        <Drawer
          placement="left"
          closable={false}
          onClose={handleCloseSidebar}
          open={openSidebar}
          key="left"
          contentWrapperStyle={{ width: "auto" }}
          bodyStyle={{ padding: 0 }}
        >
          <Sider
            trigger={null}
            theme="dark"
            collapsible
            collapsed={false}
            className="!overflow-auto !h-screen custom-sidebar"
            width={312}
          >
            <div className="h-20 flex items-center pl-5 !justify-start text-left">
              <Link to={isSA ? "/sa/dashboard" : "/admin/dashboard"}>
                <Image height={38} width="100%" src={MainLogo} alt={APP_NAME} />
              </Link>
            </div>

            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={[
                activePathString(
                  location.pathname,
                  "/",
                  dynamicPath ? true : false,
                  getActiveMenu(
                    siteRole === "ADMIN"
                      ? AdminMenuItems
                      : siteRole === "FINANCE"
                      ? FinanceMenuItems
                      : []
                  )
                ),
              ]}
              openKeys={openMenuKeys}
              onOpenChange={handleOpenMenu}
              items={
                siteRole === "ADMIN"
                  ? AdminMenuItems
                  : siteRole === "FINANCE"
                  ? FinanceMenuItems
                  : ([] as MenuItem[])
              }
              className="sidebar-menu-item"
            />
          </Sider>
        </Drawer>
      </div>
      <div className="hidden md:block">
        <Sider
          trigger={null}
          theme="dark"
          collapsible
          collapsed={collapsed}
          className="!overflow-auto !h-screen !sticky top-0 custom-sidebar"
          width={312}
        >
          <div
            className={`h-20 flex items-center ${
              collapsed
                ? "pl-0 justify-center text-center"
                : "pl-5 !justify-start text-left"
            }`}
          >
            <Link to={isSA ? "/sa/dashboard" : "/admin/dashboard"}>
              <Image
                height={collapsed ? 20 : 38}
                width="100%"
                src={MainLogo}
                alt={APP_NAME}
              />
            </Link>
          </div>

          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[
              activePathString(
                location.pathname,
                "/",
                dynamicPath ? true : false,
                getActiveMenu(
                  siteRole === "ADMIN"
                    ? AdminMenuItems
                    : siteRole === "FINANCE"
                    ? FinanceMenuItems
                    : []
                )
              ),
            ]}
            openKeys={openMenuKeys}
            onOpenChange={handleOpenMenu}
            items={
              siteRole === "ADMIN"
                ? AdminMenuItems
                : siteRole === "FINANCE"
                ? FinanceMenuItems
                : ([] as MenuItem[])
            }
            className={`sidebar-menu-item ${
              collapsed ? "sidebar_collapsed" : ""
            }`}
          />
        </Sider>
      </div>

      <Layout className="site-layout transition-all">
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <div className="flex items-center max-h-full justify-between pr-5">
            <div className="w-16 h-16 flex justify-center items-center">
              <Button
                type="text"
                icon={<SidebarBashIcon />}
                onClick={handleShowSidebar}
                className="md:hidden flex justify-center items-center"
              />
              <Button
                type="text"
                icon={collapsed ? <SidebarBashIcon /> : <SidebarBashIcon />}
                onClick={handleCollapsed}
                className="hidden md:flex justify-center items-center"
              />
            </div>

            <div className="flex items-center gap-3">
              <Dropdown
                trigger={["click"]}
                placement="bottomRight"
                arrow={{ pointAtCenter: true }}
                open={openDropdown}
                onOpenChange={setOpenDropdown}
                dropdownRender={() => (
                  <div className="w-64 bg-white shadow rounded-md">
                    <div className="flex items-center justify-between gap-2 py-1.5 px-3 shadow-sm shadow-gray-100 sticky top-0">
                      <span className="text-xs text-header font-medium">
                        Notifications
                      </span>
                      <Link
                        className="text-xs text-body font-medium hover:text-header"
                        to="/admin/notifications"
                        onClick={() => setOpenDropdown(false)}
                      >
                        View All
                      </Link>
                    </div>

                    <div>
                      <div className="h-48 overflow-y-auto notification">
                        {viewLoading ? (
                          <Loader />
                        ) : getHeaderNotifications?.length ? (
                          getHeaderNotifications?.map((el) => (
                            <div
                              className="border border-solid border-others-border border-b-0 border-x-0 first:border-t-0 border-t-[1px]"
                              key={el?._id}
                            >
                              <div className="grid grid-cols-80 gap-1.5 py-1.5 pl-1.5">
                                <div className="flex items-center justify-center">
                                  <img
                                    className="w-10 h-10 object-cover rounded-full"
                                    src={IMG}
                                    alt=""
                                  />
                                </div>
                                <div className="flex justify-between items-center">
                                  <div className="relative -top-0.5">
                                    <h6 className="text-body text-base font-medium leading-tight break-all mb-1">
                                      {el?.proprietor?.fullName || "Proprietor"}
                                    </h6>

                                    <div className="text-[#7d88f4] text-sm font-normal leading-normal break-all">
                                      {el?.description}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <Empty
                            image={EmptyImage}
                            imageStyle={{ marginBottom: 0, height: 70 }}
                            rootClassName="pt-10"
                            description={
                              <span className="text-[#8797ae] block -mt-2 text-sm font-normal font-roboto leading-7">
                                Empty
                              </span>
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              >
                <Badge count={headerNotifications?.length}>
                  <Button
                    className="border-none shadow-sm"
                    icon={<NotificationHeaderIcon />}
                    size="middle"
                  />
                </Badge>
              </Dropdown>
              <ProfileMenu />
            </div>
          </div>
        </Header>

        <Content
          style={{
            margin: 20,
            padding: "16px 20px",
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default withSidebar(SidebarLayout);
