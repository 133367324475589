import { Button } from "antd";
import { useState } from "react";
import ButtonEmailIcon from "../../assets/icons/ButtonEmailIcon";
import ViewIcon from "../../assets/icons/ViewIcon";
import { IMAGE_URL } from "../../helpers/config";
import { createNotificationAsync } from "../../store/features/admin/notification/notificationAPI";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { NotificationType } from "../../types/redux";
import { stringToArray } from "../../utils/array";
import { formatDate, isExpired } from "../../utils/time";
import ImageModal from "../admin/ImageModal";

type Props = {
  title: string;
  date?: string;
  file?: string;
  id: string;
  type: NotificationType;
};

export default function DocumentItem({ title, date, file, id, type }: Props) {
  const dispatch = useAppDispatch();
  const [openImg, setOpenImg] = useState(false);
  const { postLoading } = useAppSelector((state) => state.notification);
  const [clickType, setClickType] = useState("");

  let fileNameArr;
  if (file) {
    fileNameArr = stringToArray(file, ".");
  }

  const fileFormat = fileNameArr?.[fileNameArr?.length - 1];
  const isPopup =
    fileFormat === "png" || fileFormat === "jpeg" || fileFormat === "jpg";

  const handleRequestUpdate = (id: string, type: NotificationType) => {
    dispatch(
      createNotificationAsync({
        data: {
          proprietor: id,
          text: `Update ${
            type === "ag"
              ? "Agreement"
              : type === "tin"
              ? "TIN"
              : type === "tax"
              ? "TAX"
              : type === "nid"
              ? "NID"
              : type === "pp"
              ? "Passport"
              : type
          } Paper`,
          title: "Update",
          type,
        },
        others: {
          reRender,
        },
      })
    );
  };

  function reRender(val: boolean) {
    if (val) setClickType("");
  }

  return (
    <div className="grid grid-cols-7 items-center w-full gap-2">
      <h4 className="col-span-2 text-others-table-header text-base font-medium leading-7 px-1.5">
        {title}:
      </h4>
      <div className="col-span-2 text-accent text-sm font-medium leading-normal flex items-center gap-1 px-1.5">
        {date ? (
          <>
            <span className="text-[#8797ae]">Expire date:</span>
            <span
              className={`${
                isExpired(date) ? "text-status-danger-text" : "text-accent"
              }`}
            >
              {formatDate(date)}
            </span>
          </>
        ) : null}
      </div>

      <div className="col-span-3 flex items-center justify-between gap-2 px-1.5 py-1.5">
        {isPopup ? (
          <button
            className="text-status-info-text border-none bg-transparent cursor-pointer"
            disabled={!file}
            onClick={() => setOpenImg(true)}
          >
            <ViewIcon />
            <span className="text-sm font-medium leading-normal ml-1.5 relative -top-[1px]">
              View file
            </span>
          </button>
        ) : (
          <a
            href={file ? `${IMAGE_URL + file}` : ""}
            target="_blank"
            rel="noreferrer"
          >
            <button
              className="text-status-info-text border-none bg-transparent cursor-pointer"
              disabled={!file}
            >
              <ViewIcon />
              <span className="text-sm font-medium leading-normal ml-1.5 relative -top-[1px]">
                View file
              </span>
            </button>
          </a>
        )}

        {date && isExpired(date) ? (
          <Button
            type="primary"
            icon={<ButtonEmailIcon />}
            onClick={() => {
              handleRequestUpdate(id, type);
              setClickType(type);
            }}
            disabled={!id}
            loading={clickType === type && postLoading}
          >
            Request Update
          </Button>
        ) : (
          <Button className="normal_btn" icon={<ButtonEmailIcon />} disabled>
            Request Update
          </Button>
        )}
      </div>

      <ImageModal
        open={openImg}
        title={title}
        data={file}
        handleClose={() => setOpenImg(false)}
      />
    </div>
  );
}
