import { Form, FormProps, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DocumentIcon from "../../../../assets/icons/DocumentIcon";
import SelectDownIcon from "../../../../assets/icons/SelectDownIcon";
import CustomForm from "../../../../components/common/CustomForm";
import {
  ArrayOption,
  selectSearchOption,
} from "../../../../helpers/siteConfig";
import AdminLayout from "../../../../layouts/AdminLayout";
import { getAllBankAsync } from "../../../../store/features/admin/bank/bankAPI";
import { createBranchAsync } from "../../../../store/features/admin/branch/branchAPI";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { BranchData } from "../../../../types/redux";

const navLink = "/admin/bank-manage/branch";

export default function ManageBranch() {
  const { id, viewId } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectBank, setSelectBank] = useState("");
  const { allBanks, loading: bankLoading } = useAppSelector(
    (state) => state.bank
  );

  const onFinish: FormProps<BranchData>["onFinish"] = (values) => {
    if (id) {
      // dispatch(
      //   updateAreaAsync({
      //     data: values,
      //     others: {
      //       reRender,
      //       id,
      //     },
      //   })
      // );
    } else {
      dispatch(
        createBranchAsync({
          data: values,
          others: {
            reRender,
          },
        })
      );
    }
  };

  // redirect and re-render function
  const reRender = (val: boolean) => {
    if (val) navigate(navLink);
  };

  //   useEffect(() => {
  //     if ((id || viewId) && singleArea && updateLoading === false) {
  //       form.setFieldsValue({
  //         region: singleArea?.region?._id,
  //         areaName: singleArea?.areaName,
  //       });
  //     }
  //   }, [form, id, singleArea, updateLoading, viewId]);

  //   useEffect(() => {
  //     if (id || viewId) {
  //       dispatch(getSingleRegionAsync({ params: { id: id || viewId || "" } }));
  //     }
  //   }, [dispatch, id, viewId]);

  useEffect(() => {
    dispatch(getAllBankAsync(null));
  }, [dispatch]);

  const title = id
    ? "Update Branch"
    : viewId
    ? "View Branch"
    : "Add New Branch";

  return (
    <AdminLayout title={title}>
      {/* {viewLoading ? <Loader /> : null} */}
      <CustomForm
        onFinish={onFinish}
        title={title}
        backLink={navLink}
        // disabled={postLoading || updateLoading}
        isUpdate={id ? true : false}
        hideBtn={viewId ? true : false}
        // disabledSubmit={viewLoading}
        maxWidth={600}
        formProps={{
          form,
        }}
        formTitle={
          <span className="text-primary flex items-center gap-2">
            <span className="-ml-1 mt-1.5">
              <DocumentIcon />
            </span>
            <span className="text-lg font-medium font-roboto leading-[30px] mt-1">
              Branch Information
            </span>
          </span>
        }
      >
        <Form.Item
          name="bank"
          label="Bank"
          rules={[
            {
              required: true,
              message: "Please select bank",
            },
          ]}
        >
          <Select
            placeholder="Select"
            size="large"
            allowClear
            showSearch
            loading={bankLoading}
            filterOption={selectSearchOption}
            options={ArrayOption(allBanks, "name", "_id")}
            onChange={(val) => {
              setSelectBank(val);
              form.setFieldValue("branch", undefined);
            }}
            disabled={viewId ? true : false}
            suffixIcon={<SelectDownIcon />}
          />
        </Form.Item>
        <Form.Item
          name="name"
          label="Branch name"
          rules={[
            {
              required: true,
              message: "Please enter branch name",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="Enter branch name"
            disabled={viewId || !selectBank ? true : false}
          />
        </Form.Item>
      </CustomForm>
    </AdminLayout>
  );
}
