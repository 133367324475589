type Props = {
  width?: number;
  height?: number;
};

export default function SelectDownIcon({ width = 18, height = 10 }: Props) {
  return (
    <span className="ant-select-suffix">
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="relative top-[1px]"
      >
        <path
          d="M16.5 1L9 8.5L1.5 1"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}
