import { lazy } from "react";

import Bank from "../pages/admin/BankManage/Bank/Bank";
import ManageBank from "../pages/admin/BankManage/Bank/ManageBank";
import Branch from "../pages/admin/BankManage/Branch/Branch";
import ManageBranch from "../pages/admin/BankManage/Branch/ManageBranch";
import AdminDashboard from "../pages/admin/Dashboard";
import PayableUser from "../pages/admin/Dashboard/PayableUser";
import AdminLogin from "../pages/admin/Login";
import Notifications from "../pages/admin/Payment/Notifications";
import Payment from "../pages/admin/Payment/Payment";
import PaymentDetails from "../pages/admin/Payment/PaymentDetails";
import CreateProprietorsManage from "../pages/admin/ProprietorsManage/CreateProprietorsManage";
import ProprietorsManage from "../pages/admin/ProprietorsManage/ProprietorsManage";
import ProprietorsManageInformation from "../pages/admin/ProprietorsManage/ProprietorsManageInformation";
import Settings from "../pages/admin/Settings/Settings";
import { RouteType } from "../types";
const NotFound = lazy(() => import("../pages/NotFound"));

// auth routers
export const authRouters: RouteType[] = [
  {
    path: "/admin/dashboard",
    element: AdminDashboard,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/dashboard/payable-users",
    element: PayableUser,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/dashboard/payable-users/:id",
    element: ProprietorsManageInformation,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/proprietors-manage",
    element: ProprietorsManage,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/proprietors-manage/create",
    element: CreateProprietorsManage,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/proprietors-manage/:id",
    element: ProprietorsManageInformation,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/payment",
    element: Payment,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/payment/details/:id",
    element: PaymentDetails,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/notifications",
    element: Notifications,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/bank-manage/bank",
    element: Bank,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/bank-manage/bank/create",
    element: ManageBank,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/bank-manage/bank/update/:id",
    element: ManageBank,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/bank-manage/bank/view/:viewId",
    element: ManageBank,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/bank-manage/branch",
    element: Branch,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/bank-manage/branch/create",
    element: ManageBranch,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/bank-manage/branch/update/:id",
    element: ManageBranch,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/bank-manage/branch/view/:viewId",
    element: ManageBranch,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/settings",
    element: Settings,
    roles: ["ADMIN"],
  },

  // ====== Finance Panel ======
  // {
  //   path: "/finance/dashboard",
  //   element: FinanceDashboard,
  //   roles: ["FINANCE"],
  // },
  // {
  //   path: "/finance/pay-bill",
  //   element: PayBill,
  //   roles: ["FINANCE"],
  // },
  // {
  //   path: "/finance/settings",
  //   element: FinanceSettings,
  //   roles: ["FINANCE"],
  // },
];

// public routers
export const publicRouters: Omit<RouteType, "roles">[] = [
  {
    path: "/admin/login",
    element: AdminLogin,
  },
  // {
  //   path: "/finance/login",
  //   element: FinanceLogin,
  // },
  {
    path: "404",
    element: NotFound,
  },
  {
    path: "*",
    element: NotFound,
  },
];
