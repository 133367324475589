import { Button, Progress, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DownloadIcon from "../../../assets/icons/DownloadIcon";
import ImportExcel from "../../../components/admin/payment/ImportExcel";
import CustomTable from "../../../components/common/CustomTable";
import IconButton from "../../../components/common/IconButton";
import Subheader from "../../../components/ui/Subheader";
import { addKeyInArray } from "../../../helpers/siteConfig";
import useModal from "../../../hooks/useModal";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import { getPayableUserAsync } from "../../../store/features/admin/dashboard/dashboardAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { TableData } from "../../../types/redux";
import { countRow, percentage } from "../../../utils/number";

export default function PayableUser() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { open, toggle, handleOpen } = useModal();
  const { limit, page, handlePagination, handleLimit } = useTable(1, 10);
  const { getPayableUser, loading } = useAppSelector(
    (state) => state.adminDashboard
  );
  const { postLoading } = useAppSelector((state) => state.payment);
  const allPayableUserData = getPayableUser?.slice(
    (page - 1) * limit,
    limit * page
  );

  const [uploadCount, setUploadCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const columns: ColumnsType<TableData<any>> = [
    {
      title: "#",
      align: "center",
      width: 60,
      render: (_, __, idx) => countRow(page, limit, idx),
    },
    {
      title: "Proprietor’s name",
      dataIndex: "fullName",
      render: (val) => (
        <span className="text-header text-base font-normal">{val}</span>
      ),
    },

    {
      title: "Number of month to be paid",
      dataIndex: "yearlyPaymentNumber",
      // render: (val) => (
      //   <span className="text-header text-base font-normal">{val}</span>
      // ),
    },
    {
      title: "Total amount",
      // dataIndex: "paymentBankInfo",
      render: (val) => (
        <span className="text-header text-base font-normal">
          {val?.paymentBankInfo?.monthlyRentAmount * val?.yearlyPaymentNumber}{" "}
          TK
        </span>
      ),
    },
    {
      title: "Bank Name",
      dataIndex: "paymentBankInfo",
      render: (val) => (
        <span className="text-header text-base font-normal">
          {val?.bankName}
        </span>
      ),
    },
    {
      title: "Branch Name",
      dataIndex: "paymentBankInfo",
      render: (val) => (
        <span className="text-header text-base font-normal">
          {val?.branchName}
        </span>
      ),
    },
    {
      title: "Routing Number",
      dataIndex: "paymentBankInfo",
      render: (val) => (
        <span className="text-header text-base font-normal">
          {val?.routingNumber}
        </span>
      ),
    },

    {
      title: "Action",
      align: "center",
      render: (val) => (
        <Link to={`/admin/dashboard/payable-users/${val?._id}`}>
          <Button type="primary">Open</Button>
        </Link>
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      getPayableUserAsync({
        params: {
          month: moment().format("MMMM").toLowerCase(),
          year: moment().year(),
        },
      })
    );
  }, [dispatch]);

  const uploadedCount = (value: number, total: number) => {
    setUploadCount(value);
    setTotalCount(total);
  };

  function reRender(val: boolean) {
    if (val) {
      // getData();
      toggle();
      navigate(`/admin/payment`);
    }
  }

  return (
    <AdminLayout title="Payable User List">
      <Subheader title="Payable User List">
        <Space size="middle">
          <IconButton
            nextIcon={<DownloadIcon />}
            type="primary"
            size="large"
            onClick={() => {
              handleOpen({
                type: "IMPORT",
              });
            }}
          >
            Import Excel
          </IconButton>
        </Space>
      </Subheader>

      {postLoading && uploadCount && totalCount ? (
        <Progress
          className="fixed w-full left-0 right-0 -top-[7px] z-[99999999999999999999999999999999]"
          percent={percentage(uploadCount / 2, totalCount)}
          showInfo={false}
          success={{ strokeColor: "#384DDA" }}
        />
      ) : null}

      {open?.type === "IMPORT" && (
        <ImportExcel
          open={open.open}
          handleClose={toggle}
          reRender={reRender}
          uploadedCount={uploadedCount}
        />
      )}

      <CustomTable<TableData<any>>
        loading={loading}
        total={getPayableUser?.length}
        limit={limit}
        page={page}
        handlePagination={handlePagination}
        columns={columns}
        dataList={addKeyInArray(allPayableUserData)}
        isLimitRow={true}
        showTotalPage={true}
        handleLimit={handleLimit}
        tableClass="bill_generate_table"
        // rowSelection={{
        //   type: "checkbox",
        //   onChange: (key) => setSelectedData(key),
        //   columnWidth: 45,
        // }}
      />
    </AdminLayout>
  );
}
